import SharedListsService from 'vue-app/shared/services/shared-lists-service.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';

import PracticeArea from 'resources/practice-area.js';
import ScoutTag from 'vue-app/scout/resources/scout-tag.js';
import ScoutFirmBridge from 'resources/scout/scout-firm-bridge.js';
import Industry from 'resources/industry.js';
import ScoutMatterManager from 'resources/scout/scout-matter-manager.js';
import ScoutMatterType from 'resources/scout/scout-matter-type.js';
import ProfileTagging from 'resources/profile-tagging.js';
import BillingFirmPracticeArea from 'resources/scout/billing-firm-practice-area.js';
import ScoutWorkspace from 'resources/scout/scout-workspace.js';
import ScoutLawyerBridge from 'resources/scout/scout-lawyer-bridge.js';
import ScoutLawyer from 'resources/scout/scout-lawyer.js';
import CountryState from 'vue-app/shared/services/country-state.js';
import Court from 'resources/court.js';
import EducationalInstitute from 'resources/educational-institute.js';
import { sortScoutTags } from 'resources/utils/tag-sorter.js';
import { sortBy, uniqBy, uniq, sortedUniq } from 'lodash';

class FilterService {
  constructor(workspaceId, lawFirmBridgeId) {
    this.workspaceId     = workspaceId;
    this.lawFirmBridgeId = lawFirmBridgeId;
  }

  getFilterOptions(filterSlug, searchValue = null, limit = 6) {
    LoadingService.loading('filterOptions');

    return this.#fetchFunctionMap[filterSlug](searchValue, limit).finally(() => {
      LoadingService.done('filterOptions');
    });
  }

  #fetchFunctionMap = {
    practiceAreas:         this.#practiceAreasData.bind(this),
    firms:                 this.#firmsData.bind(this),
    lawFirmTags:           this.#lawFirmTagsData.bind(this),
    industries:            this.#industriesData.bind(this),
    matterManager:         this.#matterManagerData.bind(this),
    matterTypes:           this.#matterTypesData.bind(this),
    notables:              this.#notableExperienceData.bind(this),
    practiceAreasWorkedOn: this.#practiceAreasWorkedOnData.bind(this),
    reviewerNames:         this.#reviewerNamesData.bind(this),
    lawyerName:            this.#lawyerNameData.bind(this),
    location:              this.#locationData.bind(this),
    bars:                  this.#locationData.bind(this),
    courts:                this.#courtsData.bind(this),
    schools:               this.#shoolsData.bind(this),
    languages:             this.#languagesData.bind(this)
  };

  #dataToOptions(data, slugKey, labelKey) {
    return data.map(item => ({
      slug: item[slugKey] || item,
      label: item[labelKey] || item
    }));
  }

  #practiceAreasData() {
    return PracticeArea.query({ view: 'minimal' })
      .then(practiceAreas => {
        return this.#dataToOptions(practiceAreas, 'name', 'name');
      });
  }

  #firmsData() {
    return ScoutFirmBridge.query()
      .then(firms => {
        const sortedFirms = sortBy(firms, 'name');
        return this.#dataToOptions(sortedFirms, 'id', 'name');
      });
  }

  #lawFirmTagsData() {
    return ScoutTag.query()
      .then(scoutTags => {
        const sortedTags = sortScoutTags(scoutTags);
        return this.#dataToOptions(sortedTags, 'name', 'name');
      });
  }

  #industriesData() {
    return Industry.query()
      .then(industries => {
        return this.#dataToOptions(industries, 'label', 'label');
      });
  }

  #matterManagerData() {
    return ScoutMatterManager.query({ workspaceId: this.workspaceId })
      .then(matterManagers => {
        const sortedMatterManager = sortBy(matterManagers, 'displayName');
        const uniqMatterManagers  = uniqBy(sortedMatterManager, 'displayName');
        return this.#dataToOptions(uniqMatterManagers, 'indexValue', 'displayName');
      });
  }

  #matterTypesData() {
    return ScoutMatterType.query({ workspaceId: this.workspaceId })
      .then(response => {
        return this.#dataToOptions(response.resources);
      });
  }

  #notableExperienceData() {
    return ProfileTagging.query({ category: 'experience', sub_category: 'notable' })
      .then(notables => {
        const sortedNotables = sortBy(notables, 'internalName');
        return this.#dataToOptions(sortedNotables, 'presentedName', 'presentedName');
      });
  }

  #practiceAreasWorkedOnData() {
    return BillingFirmPracticeArea.query()
      .then(practiceAreas => {
        const sortedPracticeAreas = sortBy(uniqBy(practiceAreas, 'name'), 'name');
        return this.#dataToOptions(sortedPracticeAreas, 'name', 'name');
      });
  }

  #reviewerNamesData() {
    return ScoutWorkspace.get({ id: this.workspaceId, view: 'possible_reviewers' })
      .then(workspace => {
        const sortedReviewerNames = uniq(workspace.reviewers.map(reviewer => reviewer.fullName).sort());
        return this.#dataToOptions(sortedReviewerNames);
      });
  }

  #lawyerNameData(searchValue = null, limit = 6) {
    const callback = lawyers => {
      const uniqLawyerNames = sortedUniq(sortBy(lawyers, 'fullName').map(lawyer =>  lawyer.highlightedFullName || lawyer.fullName));
      return this.#dataToOptions(uniqLawyerNames);
    };

    if (Number.isInteger(this.workspaceId)) {
      return ScoutLawyerBridge.query(
        {
          view: 'full_names',
          workspace_id: this.workspaceId,
          law_firm_bridge_id: this.lawFirmBridgeId,
          search_value: searchValue,
          limit: limit
        }).then(callback);
    }
    else {
      return ScoutLawyer.query(
        {
          view: 'full_names',
          search_value: searchValue,
          limit: limit
        }
      ).then(callback);
    }
  }

  #locationData() {
    const bars = SharedListsService.listOfStatesWithCountry().concat(new CountryState().countries);

    return Promise.resolve(this.#dataToOptions(bars));
  }

  #courtsData() {
    return Court.query()
      .then(courts => {
        return this.#dataToOptions(courts.map(court => court.name));
      });
  }

  #shoolsData() {
    return EducationalInstitute.query({ view: 'scout' })
      .then(schools => {
        return this.#dataToOptions(schools.map(school => school.name));
      });
  }

  #languagesData() {
    return Promise.resolve(this.#dataToOptions(SharedListsService.listOfLanguages()));
  }
}

export default FilterService;
